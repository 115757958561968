import React from 'react';
import { graphql } from 'gatsby';

import { PoliciesPageTemplate } from '../templates';
import { Layout } from '../components';

const PoliciesPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    return (
        <Layout title={ frontmatter.headerTitle }>
            <PoliciesPageTemplate { ...frontmatter }/>
        </Layout>
    );
};

export default PoliciesPage;

export const pageQuery = graphql`
    query PoliciesPageQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "policies-page" } }) {
            frontmatter {
                headerImage {
                    cloudinaryMedia
                    assetType
                    deliveryType
                    urlRemainder
                    sourceURL
                }
                headerTitle
                heroTitle
                heroSubtitle
                policies {
                    name
                    icon {
                        props {
                            children {
                                key
                                props {
                                    d
                                    points
                                    strokeWidth
                                    strokeLinejoin
                                    strokeLinecap
                                    fillRule
                                    clipRule
                                }
                                type
                            }
                            attr {
                                fill
                                stroke
                                strokeLinecap
                                strokeLinejoin
                                strokeWidth
                                viewBox
                            }
                        }
                    }
                    pdf
                }
            }
        }
    }
`;